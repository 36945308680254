section.statistics {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding-bottom: 5rem;
}

.stats-title {
  font-size: 3.75rem;
  line-height: 4.125rem;
  font-weight: 600;
  color: var(--color-theme-white);
  margin: initial;
  padding-top: 3rem;
}

.stats-wrap > .entry-tag {
  margin-top: 2rem;
  margin-left: 2.025rem;
  top: 2ch;
  position: relative;
  display: inline-block;
}

.stats {
  display: grid;
  grid-row-gap: 1.5rem;
}

.stats-item {
  padding: 2.425rem 3.25rem 1.6rem;
  background: var(--color-theme-white);
  box-shadow: 6px 4px 20px rgba(0, 0, 0, 0.275);
  & > a {
	margin: initial;
	text-decoration: none;
  }
}

.stats-item-title {
  margin: initial;
  font-weight: 800;
}

.stats-item-content {
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin-top: .875rem;
  font-family: var(--global-font-family);
}

@media screen and (min-width: 40em) {
  .stats-grid {
	display: grid;
	grid: auto-flow dense 1fr/0.9fr 1fr;
  }
  .stats-title {
	font-size: 4.5rem;
	line-height: 4.75rem;
	margin-top: 13rem;
	padding-right: 2rem;
	padding-top: initial;
	max-width: 9ch;
  }
  .stats-wrap > .entry-tag {
	margin-top: 9rem;
  }

}
