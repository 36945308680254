.section-tabbed-content {
	padding-top: 3.25rem;

	& .container {
		max-width: 1120px;
	}
}

.tab-section-title {
	color: white;
}

.tab-content a {
	color: var(--color-theme-secondary);
}

.tab-nav {
	margin: initial;
	padding: initial;
	color: white;
	list-style: none;

	& ul,
	& li a {
		display: block;
		color: inherit;
		margin-bottom: 0.5rem;
		padding: 0.5rem 1rem;
	}

	& [aria-selected="true"] {
		background-color: var(--color-theme-green);
		color: var(--color-theme-primary);
	}
}

.grid-container {
	display: flex;
	flex-direction: column;

	& .tab-content {
		color: white;

		& h2 {
			color: white;
			font-weight: bolder;
			font-size: 2rem;
		}
	}

	& [hidden] {
		display: block;
	}

	& .tab-images {
		display: none;
	}
}

@media screen and (min-width: 45rem) {

	.grid-container {
		display: grid;
		grid-template-rows: 80px auto;
		grid-template-columns: 1fr 1fr 1fr 1.5fr;
		grid-row-gap: 3.25rem;

		& [hidden] {
			display: none;
		}

		& .page-title {
			grid-row: 1 / 2;
			grid-column: 1 / 2;
		}

		& .tab-list {
			grid-row: 2 / 3;
			grid-column: 1 / 2;
		}

		& .tab-picture {
			grid-row: 1 / 3;
			grid-column: 2 / 5;
			margin-left: auto;
			padding-left: 3.25rem;
		}

		& .tab-images:not([hidden]) {
			display: block;
			grid-row: 3;
			grid-column: 1 / 3;

			& img {
				margin-bottom: 3.25rem;
			}
		}

		& .tab-content {
			grid-row: 3;
			grid-column: 3/5;
			padding-left: 3.25rem;

			& h2 {
				margin-top: initial;
			}
		}
	}
}
