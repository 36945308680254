.section-staggered-entries {
  background: var(--color-theme-black);
  & .large-title {
	font-size: 2.5rem;
	font-weight: 700;
	margin-top: 6rem;
	margin-bottom: 3.25rem;
	max-width: 33ch;
  }
}

.entries-staggered {

  & .entry-tag {
	position: absolute;
	right: initial;
	left: 0rem;
	display: inline-block;
	margin-bottom: 2rem;
	top: 1.5rem;
	background: var(--color-theme-white);
	color: var(--global-font-color);
  }

  & .post-thumbnail {
	order: 0;
	display: block;
  }
  & .item {
	display: grid;
	margin-bottom: 5rem;
	color: var(--color-theme-white);
	& .item-content {
	  order: 2;
	  color: inherit;
	  padding: 0rem 0rem 3rem 0rem;
	  position: relative;
	  & .entry-title {
		margin-top: 6rem;
		font-size: 2.75rem;
		font-weight: 600;
		color: inherit;
		margin-bottom: 2rem;
	  }
	  & .entry-title a {
		text-decoration: none;
		color: var(--color-theme-white);
	  }
	  & p {
		margin-top: initial;
		font-size: 1.25rem;
		line-height: 1.85rem;
		margin-bottom: 2.5rem;
	  }
	  & .button {
		display: inline-block;
	  }
	}
	&:nth-child(even) {
	  & .post-thumbnail {
		order: 1;
	  }
	  & .item-content  {
		order: 0;
		padding: 0rem 3rem 3rem 0rem;
	  }
	  & .entry-tag {
		left: 0rem;
	  }
	}
	&:last-child {
	  margin-bottom: 6.5rem;
	}
  }
}


@media screen and (min-width: 32rem) {
  .entries-staggered {

	& .item {
		max-width: 1120px;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr;
		& .item-content {
			padding: 0rem 0rem 3rem 3rem;
			position: relative;
		}
	  }
	  & .entry-tag {
		left: 3rem;
		top: initial;
	  }
  }

  .section-staggered-entries {
	& .large-title {
	  font-size: 4.5rem;
	}
  }


}
