.isotope-item {
  z-index: 2;
}

.entry-gallery-content {
  padding-top: 1.25rem;
  padding-left: 1.35rem;
  padding-bottom: 1.75rem;
  background-color: var(--color-theme-black);
}

.entry-gallery-title a,
.entry-gallery-link,
.entry-gallery-link:visited {
  color: var(--color-theme-white);
}

.entry-gallery-link {
  font-style: italic;
}

.entry-gallery-title a {
  text-decoration: none;
}

.entry-gallery-item {
  & .entry-gallery-title a {
	font-size: 1.375rem;
  }
}

.entry-gallery-item--large {
  & .entry-gallery-title a {
	font-size: 2.25rem;
  }
}

.entry-gallery-item,
.entry-gallery-item--large {
  & .entry-gallery-title {
	margin-top: initial;
	margin-bottom: 0.75rem;
	line-height: 1em;
  }
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope-grid {
  margin-top: 2rem;
  & .item {
	/* min-height: 424px; */
	color: white;
  }
}

.isotope-grid:after {
  content: '';
  display: block;
  clear: both;
}

/* .grid-sizer,
.entry-gallery-item,
.entry-gallery-item--large {
  float: left;
} */

.entry-gallery-item,
.grid-sizer {
  width: 22%;
}

.entry-gallery-item--large {
  width: 48%;
}

.gutter-sizer { width: 4%; }

@media screen and (max-width: 600px) {
  .grid-sizer,
  .entry-gallery-item {
	width: 48%;
  }
  .entry-gallery-item--large {
	width: 98%;
  }
  .gutter-sizer { width: 2%; }
}
