.site-main {
	position: relative;
}

.icon {
	width: 100%;
	height: 100%;
}

.container {
	max-width: 1120px;
	padding: 0 1rem;
	margin: 0 auto;
	position: relative;
}

.container-medium {
	max-width: 70ch;
	margin: 0 auto;
}

.logo-img {
	width: 250px;
	height: auto;
	margin: 0 auto;
}

.site-header .logo-img {
	padding-top: 0.5em;
}

.content {
	position: relative;
}

.underline {
	text-decoration: none;
	border-bottom: 2px solid currentColor;
	display: inline-block;
	line-height: 0.85;
	text-shadow: 2px 2px white, 2px -2px white, -2px 2px white, -2px -2px white;
}

.button {
	background-color: var(--color-theme-green);
	font-weight: 600;
	color: var(--global-font-color);
	text-decoration: none;
	padding: 0.675rem 1.875rem;
	border-radius: 1.8rem;
}

.overflow-auto {
	overflow: auto;
}

.entry-tag.white-tag {
	color: var(--global-font-color);
	background-color: var(--color-theme-white);
}

.green-bg {
	background: var(--color-theme-green);
}

.blue-bg {
	background: var(--color-theme-blue);
}

.black-bg {
	background: var(--color-theme-black);
}

.grey-bg {
	background: var(--color-theme-grey);
}

.white-bg {
	background: var(--color-theme-white);
}

.inline-block {
	display: inline;
}
