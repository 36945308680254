.section-entries {
  background: var(--color-theme-blue);
  padding-bottom: 4rem;
  overflow: hidden;

  & .large-title {
	margin-top: initial;
	font-weight: 700;
	margin-bottom: 3.75rem;
	max-width: 33ch;
  }
  & .entry-tag {
	position: relative;
	display: inline-block;
	margin-top: 4.5rem;
	margin-bottom: 2rem;
	top: initial;
	background: var(--color-theme-white);
	color: var(--global-font-color);
  }
}

.entry-grid {
  display: grid;
  margin-top: 6rem;
  grid-gap: 4.5rem;

  & .post-thumbnail {
	margin-right: -2rem;
	margin-top: -2rem;
	margin-left: 2rem;
	display: block;
  }

  & .item {
	background: var(--color-theme-white);
	& .item-content {
	  padding: 0 1rem 2rem 2rem;
	  & .entry-title {
		font-weight: 600;
		margin-bottom: 0.875rem;
	  }
	  & .entry-title a {
		color: var(--global-font-color);
	  }
	  & p {
		margin-top: initial;
		line-height: 1.5rem;
	  }
	  & .button {
		display: inline-block;
	  }
	}
  }
}


@media screen and (min-width: 32rem) {
  .entry-grid {
	max-width: 1200px;
	grid-template-columns: repeat(auto-fill,minmax(310px,1fr));
  }
}
