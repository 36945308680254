.footer-columns {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
  grid-column-gap: 2rem;
  justify-items: stretch;
  font-family: var(--global-font-family);
  & .brand > .footer-title {
	font-weight: 900;
	font-family: var(--highlight-font-family);
	border: initial;
	text-transform: initial;
	margin-bottom: initial;
  }
  & .brand {
	margin-bottom: 1rem;
  }
  & .footer-title {
	text-transform: uppercase;
	margin-bottom: initial;
	margin-top: initial;
	font-family: var(--global-font-family);
	font-weight: 400;
	border-bottom: 1px solid var(--color-theme-primary);
  }
  & .footer-title > a {
	text-decoration: none;
  }
  & .footer-content {
	font-family: var(--global-font-family);
	font-size: 1rem;
  }
}

.site-footer {
  margin-top: 5rem;
}

@media screen and (max-width: 600px) {
  .footer-column .footer-title {
	display: inline-block;
	margin-top: 1rem;
  }
  .footer-column .brand {
	margin-bottom: 2rem;
  }
}
