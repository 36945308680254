.columns {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.twocol .section-title {
  color: white;
  margin-top: initial;
}

.twocol {
  padding-top: 3.75rem;
  padding-bottom: 4.75rem;
}

.column {
  color: white;
  & > * {
	color: white;
  }
}
