.entry-tag {
  background: var(--color-theme-black);
  color: #fff;
  padding:  0.675rem 1.275rem .5rem;
  text-transform: uppercase;

  font-weight: 500;
  letter-spacing: 0.125rem;
  position: absolute;

  top: -3.5ch;
  left: 0ch;
  font-size: 0.85rem;

  &.center {
    margin: 0 auto;
    display: inline-block;
    position: relative;
    margin-bottom: -2rem;
    justify-self: center;
  }
}

@media screen and (min-width: 32rem) {
  .entry-tag {
    font-size: 1rem;
    top: -2.5ch;
    left: initial;
  }
}

