/**
 *
 * CSS variables.
 *
 * @link: https://developer.mozilla.org/en-US/docs/Web/CSS/--*
 **/

 :root {
	--global-font-color: #291F1E;
	--global-font-family: apertura, sans-serif;
	--global-font-size: 18;
	--global-font-line-height: 1.5;
	--highlight-font-family: p22-mackinac-pro, serif;
	--content-width: 70ch;
	--dropdown-symbol-width: 0.7em;

	--color-theme-primary: #243E5A;
	--color-theme-secondary: #D4F2CE;
	--color-theme-green: var(--color-theme-secondary);
	--color-theme-blue: var(--color-theme-primary);
	--color-theme-black: var(--global-font-color);
	--color-theme-grey: #BAC7BE;
	--color-theme-white: #fff;
	--color-link: var(--color-theme-primary);
	--color-link-visited: var(--global-font-color);
	--color-link-active: var(--color-theme-primary);
	--color-quote-border: var(--color-theme-grey);
	--color-quote-citation: var(--global-font-color);
	--color-theme-blue: var(--color-theme-primary);

	/* Custom editor font sizes */
	--font-size-small: calc(18 / var(--global-font-size) * 1rem);
	--font-size-regular: calc(var(--global-font-size) / 16 * 1rem);
	--font-size-large: calc(36 / var(--global-font-size) * 1rem);
	--font-size-larger: calc(48 / var(--global-font-size) * 1rem);
}
