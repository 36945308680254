.section-list {
  padding-top: 4rem;
  padding-bottom: 3rem;
	& .container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
  & .grid-list {
	max-width: 100%;
	display: grid;
	flex-basis: 100%;
	align-items: baseline;
	justify-content: space-between;
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
  }
  & .large-title {
	margin-top: initial;
	padding-top: 2rem;
  }
  & .list-title {
	margin-top: initial;
	margin-bottom: 0.875rem;
  }
  & .entry-tag {
	top: calc(-2.5ch - 4rem);
  }
  & .entry-tag.alt {
	position: relative;
	top: initial;
  }
  & p {
	margin-top: initial;
	line-height: 1.5rem;
  }
}

.section-list.blue-bg,
.section-list.black-bg {
  & .grid-list-item {
	& p,
	& h3 {
	  color: white;
	}
  }
}

.grid-list-item {
  display: flex;
  flex-direction: column;
  & .icon {
	max-width: 4rem;
	max-height: 4rem;
	margin-bottom: 1rem;
  }
}

@media screen and (min-width: 45rem) {
  .section-list {
	& .grid-list {
	  grid-template-columns: repeat(auto-fill,minmax(calc(25% - 2rem),1fr));
	}
  }
}
