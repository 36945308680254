.hero {
  width: 100%;

  & .hero-bg {
	padding-top: 10ch;
	padding-bottom: 18ch;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
  }

  & .content {
	position: relative;
	background: white;
	padding: 4ch 2ch;
	display: inline-block;
	max-width: 100%;
	
  }

  & .large-title {
	margin-top: initial;
	margin-bottom: 1.5rem;
	word-break: break-word;
  }

}

